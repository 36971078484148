<template>
  <div>
    <headers />
    <div class="head">
      <img src="@/assets/images/productManualDetailsBj.png" />
      <div class="title">{{ details.name }}</div>
    </div>
    <div class="breadcrumb">
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item to="/">首页</el-breadcrumb-item>
        <el-breadcrumb-item>资料获取</el-breadcrumb-item>
        <el-breadcrumb-item to="/information/productManual">产品手册</el-breadcrumb-item>
        <el-breadcrumb-item>{{details.name}}</el-breadcrumb-item>
      </el-breadcrumb>
    </div>

    <div class="content">
      <div class="imgBox">
        <img class="hoverTransform" :src="details.image" />
      </div>

      <div class="contentR">
        <div class="title">{{ details.name }}</div>
        <div class="title2" v-html="details.productDesc"></div>

        <el-button class="searchBoxR" @click="toPdfIndex">预览/下载</el-button>
      </div>
    </div>
    <footers />
  </div>
</template>

<script>
import headers from "@/components/header.vue";
import footers from "@/components/footer.vue";
import search from "@/components/search.vue";

import { productView } from "@/api/lx";

export default {
  name: "productManualDetails",
  components: {
    headers,
    footers,
    search,
  },
  data() {
    return {
      details: {},
    };
  },
  created() {
    this.id = this.$route.query.id;
    this.getProductView();
  },
  mounted() {},
  methods: {
    getProductView() {
      productView(this.id).then((res) => {
        this.details = res.data;
      });
    },
    actMenuOne(val) {
      if (this.actOneMeun == val.id) {
        this.actOneMeun = "";
      } else {
        this.actOneMeun = val.id;
      }
    },
    toPdfIndex() {
      this.$router.push({
        path: "/pdf/index",
        query: { url: this.details.fileUrl,name: this.details.name },
      });
    },
  },
};
</script>

<style lang="less" scoped>
.head {
  width: 100%;
  height: 240px;
  position: relative;
  overflow: hidden;
  .title {
    width: 100%;
    text-align: center;
    font-family: PingFangSC, PingFang SC;
    font-weight: 600;
    font-size: 42px;
    margin-top: 91px;
    color: #ffffff;
    position: relative;
    z-index: 10;
  }
  img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}
.content {
  width: 1400px;
  margin: 0 auto;
  margin-top: 30px;
  display: flex;
  background-color: #fff;
  padding: 40px;
  box-sizing: border-box;
  margin-bottom: 55px;
  .imgBox {
    width: 330px;
    height: 330px;
    overflow: hidden;
    border: 1px dashed #e6edf0;
    img {
      width: 100%;
      height: 100%;
    }
  }
  .contentR {
    width: 930px;
    margin-left: 40px;
    min-height: 500px;
    .title {
      font-weight: 600;
      font-size: 22px;
      color: #00161a;
    }
    .title2 {
      font-family: PingFangSC, PingFang SC;
      font-weight: 400;
      font-size: 16px;
      color: #525f66;
      line-height: 28px;
      text-align: left;
      font-style: normal;
      margin-top: 25px;
      min-height: 213px;
    }
    .searchBoxR {
      width: 127px;
      height: 39px;
      background: #00d8c3;
      font-weight: 600;
      font-size: 14px;
      color: #ffffff;
      margin-top: 25px;
      &.is-active,
      &:active {
        border: 1px solid #00d8c3; /* 更改为你想要的颜色 */
        outline: none; /* 移除默认的轮廓线 */
      }
    }
  }
}
</style>